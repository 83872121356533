import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import memegenarator from '../../Images/trollface.png'
import ToDoList from '../../Images/todolist.jpg'
import Inventory from '../../Images/inventory.jpg'

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Inventory}
              isBlog={false}
              title="Inventory Management System"
              description="A system designed to keep track of incoming and outgoing inventory, along with the users of the items and dates of activities. It stores data in the browser's local storage as no backend frameworks or databases were used in the creation of this project "
              ghLink="https://github.com/khua-dev/Inventory-management-system-v1.0"
              demoLink="https://thekhuainventory.pages.dev"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={memegenarator}
              isBlog={false}
              title="Meme genarator"
              description="A real time meme genarator that allows user to add custom captions at the top and bottom of the genarated meme image"
              state="UNDER CONSTRUCTION FOR PRODUCTION USE"
              // ghLink=""
              // demoLink=""
            />
           
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ToDoList}
              isBlog={false}
              title="To-Do-List"
              description="A web app capable of CRUD operations by keeping track of user planned activities and showing tasks that have been and are yet to be completed"
              state="UNDER CONSTRUCTION FOR PRODUCTION USE"
              // ghLink=""
              // demoLink=""
            />
    
          </Col>

          

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
